import { Link } from "react-router-dom";

function Main() {
    return (
        <div className="container-fluid M-div background_fade">
            <br></br>
          <h1 className="text-center font-title">
            La solution à l'oublie médicamenteux
          </h1>
          <br></br>
          <div className="container">
            <div className="row">
            <div className="col-sm-4">

            </div>
              <div className="col-sm-4">
                <div className="card Morph_White_50 home-card mb-3">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Pourquoi utiliser Remindoc ?
                    </h5>
                    <p className="card-text text-center">
                    RemindDoc est une solution gratuite qui vous aide à assurer la prise quotidienne de vos médicaments.<br></br>
                    Il vous permet également de partager votre calendrier de prise de médicaments avec un proche, afin qu'il puisse 
                    vous rappeler de prendre votre traitement si vous oubliez.
                    </p>

                  </div>
                  <img className="img-fluid" src="/images/brain.webp"></img>
                </div>
              </div>
              <div className="col-sm-4">

              </div>
            </div>
          </div>
          <center>
            <Link className="about-button font-title Morph_White_50 Morph_Nav_Btn" to="/about">En savoir plus</Link>
          </center>
          <br></br>
          <br></br>
        </div>
    );
}

export default Main;