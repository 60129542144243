import { Link } from 'react-router-dom';
import '../css/styles.css';
import '../css/Morph.io.css';

function Navbar_Build() {
    return (
        <div>
        <nav className="navbar navbar-expand-lg background_green">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                    <Link className="font-title nav-size nav-link Morph_Nav_Btn" aria-current="page" to="/">Accueil</Link>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
        </div>
    );
}

export default Navbar_Build;